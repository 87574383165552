<template lang="vi">
<div>
  <address-filter :reset="reset" @reset="setting_reset" />
  <div class="row">
      <div class="col-lg-7">
          <div class="row">
              <div class="col-lg-4">
                  <b-form-group>
                      <input type="text" class="form-control" v-model="search" placeholder="Nhập tiêu đề" />
                  </b-form-group>
              </div>
              <div class="col-lg-4" style="margin-bottom: 15px;">
                  <b-form-select v-model="category" aria-placeholder="a" style="cursor: pointer;">
                      <b-form-select-option :value="null" disabled>Phân loại</b-form-select-option>
                          <option v-for="(cat) in categories" :key="cat" :value="cat">
                              {{ cat }}
                          </option>
                  </b-form-select>        
              </div>
              <div class="col-lg-4" style="margin-bottom: 15px; cursor: pointer">
                  <date-picker placeholder="Ngày tạo" v-model="ngayBanHanh" valueType="format" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY">
                  </date-picker>
              </div>
          </div>
      </div>
      <div class="col-lg-2">
              <b-button variant="light" class="btn-refresh" @click="refresh">
                      <i class="fas fa-sync"></i>
              </b-button>
      </div>
  </div>
</div>
</template>

<script>
import TableSearch from "../../../../components/datatable/table-search";
import { httpClient } from "../../../../_utils/httpClient";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/vi";
import addressFilter from "../../../../components/filter/address-filter.vue";

export default {
  name: "product-filter",
  components: {
    TableSearch,
    DatePicker,
    addressFilter
  },
  data() {
    return {
      category: null,
      categories: [],
      province: null,
      provinces: [],
      ngayHieuLuc: null,
      ngayBanHanh: null,
      search: "",
      reset: false,
    };
  },
  watch: {
    search: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.search == '') {
          delete query.search
          this.$router.push({
            name: this.$route.name,
            query: query,
          });
        }
        if (this.search.length > 0) {
          query.search = this.search;
          setTimeout(() => {
            if (query.search == this.search || (this.search.length < query.search.length && query.search.length == this.search.length)) {
              this.$router.push({
                name: this.$route.name,
                query: query,
              });
            }
          }, 800);
        } else {
          delete query.search;
        }
      },
      deep: true,
    },
    category: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.category) {
          query.phanLoai = this.category;
        } else {
          delete query.phanLoai;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      },
      deep: true,
    },
    ngayHieuLuc: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.ngayHieuLuc) {
          query.ngayHieuLuc = this.ngayHieuLuc;
        } else {
          delete query.ngayHieuLuc;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      },
      deep: true,
    },
    ngayBanHanh: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.ngayBanHanh) {
          query.ngayTao = this.ngayBanHanh;
        } else {
          delete query.ngayTao;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      },
      deep: true,
    },
  },
  methods: {
    setting_reset() {
      this.reset = false
    },
    async getCategories() {
      let response = await httpClient.get(`config`, {
        params: {
          name: "phanloaiquyetdinh",
        },
      });
      this.categories = response.data.data.map((el) => {
        return el.name;
      });
    },
    refresh() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.reset = true
      // Hàm có chức năng sao chép data ban đầu vào data hiện tại
      // this.$data: tất cả các giá trị của data hiện tại
      // this.$options.data.apply(this): tất cả các giá trị của data lúc khởi tạo ban đầu
      this.getCategories();
    },
    getData() {
      let query = this.$route.query
      if (query.search) {
        this.search = query.search
      }
      if (query.phanLoai) {
        this.category = query.phanLoai
      }
      if (query.ngayTao) {
        this.ngayBanHanh = query.ngayTao
      }
    }
  },
  async created() {
    await this.getCategories();
    this.getData();
  },
};
</script>

<style scoped>
.mx-datepicker,
.mx-input-wrapper,
.mx-input {
  height: 41px !important;
}
</style>
